<template>
  <v-container>
    <base-header>
      <div slot="page-header">
        <h2 class="font-weight-black primary--text">Transactions</h2>
      </div>
    </base-header>
    <v-data-table v-model="selected"
                  height="60vh"
                  :headers="headers"
                  :loading="loading"
                  :items="transactions"
                  :page="pagination.page"
                  :footer-props="{
                          'items-per-page-options':[10,30,50,100,200],
                          itemsPerPageText:''
                        }"
                  @update:items-per-page="itemsPerPageChange"
                  @update:page="pageChange"
                  @update:sort-by="sortChange"
                  @update:sort-desc="sortDescChange"
                  :server-items-length="pagination.totalItems"
                  :items-per-page="pagination.rowsPerPage"
                  loading-text="Loading... Please wait"
                  item-key="name"
                  :class="[selected && 'selected']"
                  class="elevation-1">
      <template v-slot:item.status="{ item }">
        <span
            :class="(item.status==='FAILED'?'red--text':item.status==='SUCCESS'?'green--text':'yellow--text')+' font-weight-bold'">
        {{ item.status }}
        </span>
      </template>
      <template v-slot:item.created="{ item }">
        {{ new Date(item.created).toLocaleString() }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import BaseCard from "../../components/Base/BaseCard.vue";
import BaseHeader from "../../components/Base/BaseHeader.vue";
import axios from "axios";

export default {
  name: 'transactions',
  components: {
    BaseCard,
    BaseHeader
  },
  methods: {
    pageChange(newPage) {
      this.pagination.page = newPage
      this.fetchTransactions()
    },
    itemsPerPageChange(newPerPage) {
      this.pagination.rowsPerPage = newPerPage
      this.fetchTransactions()
    },
    sortChange(newSort) {
      this.pagination.sortBy = newSort[0]
      this.fetchTransactions()
    },
    sortDescChange(newSortDesc) {
      this.pagination.descending = newSortDesc[0]
      this.fetchTransactions()
    },
    doSearch() {
      if (this.search) {
        this.fetchTransactions(this.search)
      } else {
        this.fetchTransactions()
      }
    },
    fetchTransactions(searchString = undefined) {
      this.loading = true;
      let url = process.env.VUE_APP_BASE_API_URL + '/admin/transaction';
      axios.get(url, {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.rowsPerPage,
          sort: `${this.pagination.sortBy || 'created'},${this.pagination.descending === undefined || this.pagination.descending === true ? 'desc' : 'asc'}`,
          search: searchString,
        }
      }).then(resp => {
        this.transactions = resp.data.content;
        this.pagination.totalItems = resp.data.totalElements
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  created() {
    this.fetchTransactions()
  },
  data() {
    let data = {
      pagination: {
        descending: true,
        sortBy: 'created',
        rowsPerPage: 10,
        page: 1,
        totalItems: 0,
      },
      search: null,
      loading: false,
      transactions: [],
      selected: [],
      headers: [
        {
          text: 'Hash',
          sortable: false,
          value: 'hash',
        },
        {
          text: 'Description',
          sortable: true,
          value: 'description'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status'
        },
        {
          text: 'Currency',
          sortable: true,
          value: 'cryptoCurrency',
          width:100
        }, {
          text: 'Created',
          sortable: true,
          value: 'created',
          width: 200
        },
      ],
    }
    return data;
  },
};
</script>
<style scoped>
.selected {
  cursor: pointer;
}
</style>
